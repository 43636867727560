<template>
  <div class="pane_body">
    <v-card flat class="breads">
      <v-toolbar flat dense class="breads">
        <v-breadcrumbs :items="breadcrumb">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :to="item.to" :disabled="item.disabled">
              {{ item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-toolbar>
    </v-card>

    <v-container>
      <v-card color="transparent" flat style="margin-bottom: 26px">
        <v-card-text style="padding: 0px;">
          <v-row class="dash_stats">
            <v-col cols="12" sm="3">
              <v-card flat class="cards">
                <v-card-text style="padding: 0px">
                  <p class="value">0</p>
                  <p class="label">medics</p>
                  <div class="graf">
                    <v-sparkline
                      smooth="15"
                      auto-draw
                      :value="value"
                      color="rgb(197, 201, 224)"
                      line-width="2"
                      padding="1"
                    ></v-sparkline>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="3">
              <v-card flat class="cards">
                <v-card-text style="padding: 0px;">
                  <p class="value">0</p>
                  <p class="label">pharmacy</p>
                  <div class="graf">
                    <v-sparkline
                      auto-draw
                      smooth="15"
                      :value="value"
                      color="rgb(197, 201, 224)"
                      line-width="2"
                      padding="1"
                    ></v-sparkline>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="3">
              <v-card flat class="cards">
                <v-card-text style="padding: 0px;">
                  <p class="value">0</p>
                  <p class="label">laboratory</p>
                  <div class="graf">
                    <v-sparkline
                      :value="value"
                      auto-draw
                      smooth="15"
                      color="rgb(197, 201, 224)"
                      line-width="2"
                      padding="1"
                    ></v-sparkline>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="3">
              <v-card flat class="cards">
                <v-card-text style="padding: 0px;">
                  <p class="value">0</p>
                  <p class="label">delivery</p>
                  <div class="graf">
                    <v-sparkline
                      smooth="15"
                      auto-draw
                      :value="value"
                      color="rgb(197, 201, 224)"
                      line-width="2"
                      padding="1"
                    ></v-sparkline>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card flat>
        <v-card-text>
          <div class="dash_chart">
            <apexcharts
              width="99%"
              height="315"
              :options="chartOptions"
              :series="series"
            ></apexcharts>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  import VueApexCharts from "vue-apexcharts";
  import dash_charts from "./../../test/dash_charts.json";
  export default {
    components: {
      apexcharts: VueApexCharts,
    },
    data() {
      return {
        gradart: ["rgba(219, 219, 219, .9)", "rgba(219, 219, 219, .1)"],
        breadcrumb: [
          {
            text: "Dashboard",
            disabled: true,
            to: { name: "admin.dash" },
          },
        ],
        // breadcrumb: [
        //   {
        //     text: "Dashboard",
        //     disabled: true,
        //     href: "breadcrumbs_dashboard",
        //   },
        // ],
        chartOptions: {
          chart: {
            type: "area",
            sparkline: {
              enabled: false,
            },
            id: "basic-bar",
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
          xaxis: {
            // categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
            categories: null,
          },
          fill: {
            opacity: 0.7,
            type: "area",
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 90, 100],
            },
          },
          responsive: [
            {
              breakpoint: 800,
              options: {
                plotOptions: {
                  bar: {
                    horizontal: false,
                  },
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          dropShadow: {
            enabled: false,
            enabledOnSeries: "basic-bar",
            top: 0,
            left: 0,
            blur: 3,
            color: "#000",
            opacity: 0.75,
          },
        },
        series: [
          {
            name: "series-1",
            data: [18, 40, 45, 50, 19, 60, 40, 21],
          },
          {
            name: "series-1",
            data: [20, 20, 15, 33, 49, 30, 20, 91],
          },
          {
            name: "series-1",
            data: [32, 40, 45, 50, 39, 40, 73, 21],
          },
        ],
        labels: ["12am", "3am", "6am", "9am", "12pm", "3pm", "6pm", "9pm"],
        value: [200, 675, 410, 390, 310, 460, 250, 240],
      };
    },
    created() {
      this.usageOvertime();
    },
    methods: {
      usageOvertime() {
        console.log(dash_charts);
        let result = dash_charts;

        let brandnamd = result.map((a) => a.brand_name);
        let quantity = result.map((a) => a.getQuantity);
        let sunday = result.map((a) => a.sunday);
        this.categories = brandnamd;
        console.log(brandnamd);
        console.log(quantity);
        console.log(sunday);
        // let labels = result.labels
        // this.categories = labels
      },
    },
  };
</script>
>
